import { IdType } from './users.types';

export enum roles {
  ADMINISTRADOR = 'ADMINISTRADOR',
  PROPIETARIO = 'PROPIETARIO',
  FIRMANTE = 'FIRMANTE',
  REPORTEADOR = 'REPORTEADOR',
  AUDITOR = 'AUDITOR',
}

export enum rolesWithSuperAdmin {
  ADMINISTRADOR = 'ADMINISTRADOR',
  PROPIETARIO = 'PROPIETARIO',
  FIRMANTE = 'FIRMANTE',
  REPORTEADOR = 'REPORTEADOR',
  AUDITOR = 'AUDITOR',
  SUPERADMINISTRADOR = 'SUPERADMINISTRADOR'
}


export const idTypes: IdType[] = [
  {
    code: 'RC',
    name: 'Registro civil',
    value: 'REGISTRO_CIVIL',
  },
  {
    code: 'CC',
    name: 'Cédula de ciudadanía',
    value: 'CEDULA_CIUDADANIA',
  },
  {
    code: 'TE',
    name: 'Tarjeta de extranjería',
    value: 'TARJETA_EXTRANJERIA',
  },
  {
    code: 'DE',
    name: 'Documento extranjero',
    value: 'DOCUMENTO_EXTRANJERO',
  },
  {
    code: 'NIT',
    name: 'Número de identificación tributaria',
    value: 'NIT',
  },
  {
    code: 'TI',
    name: 'Tarjeta de identidad',
    value: 'TARJETA_IDENTIDAD',
  },
  {
    code: 'CE',
    name: 'Cédula de extranjería',
    value: 'CEDULA_EXTRANJERIA',
  },
  {
    code: 'PEP',
    name: 'Permiso especial de permanencia',
    value: 'PEP',
  },
  {
    code: 'NUIP',
    name: 'Número único de identificación personal',
    value: 'NUIP',
  },
  {
    code: 'NITI',
    name: 'NIT Internacional',
    value: 'NIT_INTERNACIONAL',
  },
  {
    code: 'PA',
    name: 'Pasaporte',
    value: 'PASAPORTE',
  },
];
