// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    identityPoolId: 'us-east-1:ff2c9992-e1c2-4436-9482-426b4f8298b2',
    region: 'us-east-1',
    userPoolId: 'us-east-1_oFhnJ3pEK',
    userPoolWebClientId: '762f4d99gtt3otpjvpihug3pbt',
  },
  s3: {
    region: 'us-east-1',
    bucket: 'elogicworkflow-qa-files',
    accessKey: 'AKIAVLCHWNVNTUNGA3OV',
    secretKey: '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',
  },
  api: {
    authPy: 'https://l5wk295lsa.execute-api.us-east-1.amazonaws.com/workflowpy',
    account: 'https://qnz4uudc1k.execute-api.us-east-1.amazonaws.com/account',
    flow: 'https://gf1lxhj17d.execute-api.us-east-1.amazonaws.com/flow',
    reportAndNotification: 'https://gu948blsx1.execute-api.us-east-1.amazonaws.com/reportAndNotification',
    proApi: 'https://pro.ip-api.com/json/?key=OrZPmbiolZbUUnG',
    biometrics: 'https://fjidiwq40h.execute-api.us-east-1.amazonaws.com/imageProcessingRequest',
    hClient: 'https://hclient.certicamara.com.co:9625/huellasManager/huella',
    hClientConf: 'https://dzwzmze0fc.execute-api.us-east-1.amazonaws.com/configuration/certihuella',
    pdfBuilder: 'https://l9ebo9o3hl.execute-api.us-east-1.amazonaws.com/api',
  },
  aes: {
    uuid: '1d81fc65-7745-4265-8fac-f001be9850b3',
    key: 'qfm5V1s8mzXwo9c6tuiyuPuHuiQh8Ozf0MUkEmMq',
  },
  biometrics: {
    account: '5e73ba79-9159-49a5-a5cc-56eb41c2f590'
  },
  pdfbuilder: {
    clientId: 'bdba983c-5c06-4ca5-b6f3-2a01ba044648',
    url: 'https://www.pdfbuilder.dev',
    redirectUrl: 'public/pdf-builder/template-callback'
  },
  syncfusion: {
    key: 'ORg4AjUWIQA/Gnt2VFhiQllPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhTd0RiWnhbdnJVQGY=',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
