import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { AuthService, AuthUtils } from 'app/core/auth';
import { DEFAULT_TIMEOUT, TIMEOUT_COUNT } from 'app/core/auth/auth.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private _authService: AuthService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Intercept
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();

    // Check if timeout must be overridden
    const timeoutValue = req.context.get(TIMEOUT_COUNT) || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    // If the access token didn't expire...
    if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
      // Add the Authorization header
      newReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this._authService.accessToken
        ),
      });
    }

    // Generate the response
    return next.handle(newReq).pipe(
      timeout(timeoutValueNumeric),
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          this._authService.signOut();
        }

        // Throw the error
        return throwError(error);
      })
    );
  }
}
