import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { SnackbarService } from 'app/core/snackbar';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class EBiometricsService {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _snackBar: SnackbarService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  encrypt(signer: any): Observable<string> {
    const body = { accountUuid: environment.biometrics.account, data: this._transform(signer) };
    const options = { params: { type: 'encrypt' } };

    return this._httpClient
      .post<{ resultData: string }>(`${environment.api.biometrics}/parametersService`, body, options)
      .pipe(
        map((response) => response.resultData),
        catchError((error) => {
          this._snackBar.showError(error.error.message);
          return of(error);
        })
      );
  }

  countTransaction(id: string): Observable<boolean> {
    return this._httpClient
      .post<{ resultData: string }>(`${environment.api.flow}/biometricsRequest`, { id })
      .pipe(
        map(() => true),
        catchError((error) => {
          console.error(error);
          return of(false);
        })
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _transform(signer: any): string {
    return (
      `${signer.names}|${signer.lastNames}|${signer.documentType}|${signer.id}` +
      `|${'2000-01-01'}|${new Date().toISOString()}`
    );
  }
}
