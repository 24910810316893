/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class S3Service {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _s3Client: S3Client;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _httpClient: HttpClient) {
    // Initialize the S3 Client
    this._s3Client = new S3Client({
      region: environment.s3.region,
      credentials: {
        accessKeyId: environment.s3.accessKey,
        secretAccessKey: environment.s3.secretKey,
      },
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Upload an object to Amazon S3 from a file
   */
  public uploadFromFile(file: File, key: string): Observable<any> {
    // Set the put object command input
    const input: any = {
      Bucket: environment.s3.bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    // Send the put object command
    return from(this._s3Client.send(new PutObjectCommand(input)));
  }

  /**
   * Upload an object to Amazon S3 from Base64 data
   */
  public uploadFromBase64(base64Data: string, key: string): Observable<any> {
    // Prepare the Base64 data
    const file = Buffer.from(base64Data.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    // Get the file type
    const type = base64Data.split(';')[0].split('/')[1];

    // Set the put object command input
    const input: any = {
      Bucket: environment.s3.bucket,
      Key: key,
      Body: file,
      ContentEncoding: 'base64',
      ContentType: `image/${type}`,
      ACL: 'public-read',
    };

    // Send the put object command
    return from(this._s3Client.send(new PutObjectCommand(input)));
  }

  /**
   * Convert docx to pdf
   */
  docxToPdf(key: string, type: string): Observable<string> {
    // Generate the path
    const path = `https://s3.amazonaws.com/${environment.s3.bucket}/${key}`;

    // Convert to pdf
    return this._httpClient
      .post<{ path: string }>(`${environment.api.flow}/parseDocumentToPDF`, { path, type })
      .pipe(map(
        (response) =>
          // Return the path
          response.path
      ));
  }
}
